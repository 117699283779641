
h1{
    font-family: Arial, sans-serif;
}

.bg {
    animation: slide 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, rgb(249, 98, 43) 33%, gold 33%,rgb(162, 0, 255) 33%);
    bottom: 0;
    left: -50%;
    opacity: .5;
    position: fixed;
    right: -50%;
    top: 0;
    z-index: -1;
  }
  
  .bg2 {
    animation-direction: alternate-reverse;
    animation-duration: 10s;
  }
  
  .bg3 {
    animation-duration: 15s;
  }
  
  @keyframes slide {
    0% {
      transform: translateX(-25%);
    }
    100% {
      transform: translateX(25%);
    }
  }

.pageQwinto{
    min-height: 100vh;
    
}
.Board{
    padding-top: 10%;
    display: flex;
    justify-content: center;
}
.QwintoBoard{
    display: grid;
    grid-template-rows: repeat(3, 60px);
    max-width: 100%;
    margin: 0  auto;
    
}
.QwintoDice{
    display: flex;
    align-items: center; /* Aligns all child elements in the center vertically */
    gap: 10px; /* Adds space between the items */
}
.QwintoSpaceSquare{
    background-color: rgba(0, 255, 255, 0);
    border: 0;
    width: 50px;
    height: 50px;
}
.QwintoCircleSquare{
    border-radius: 50%;
    width: 50px;
    height: 50px;
}
.die {
    width: 50px;
    height: 50px;
    background-color: white;
    border: 3px solid black;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .pip {
    width: 10px;
    height: 10px;
    background-color: black;
    border-radius: 50%;
    position: absolute;
  }
  
.pip.one { top: 50%; left: 50%; transform: translate(-50%, -50%); }
.pip.two { top: 20%; left: 20%; }
.pip.three { top: 20%; right: 20%; }
.pip.four { bottom: 20%; left: 20%; }
.pip.five { bottom: 20%; right: 20%; }
.pip.six { top: 50%; left: 20%; transform: translateY(-50%); }
.pip.seven { top: 50%; right: 20%; transform: translateY(-50%); }
.QwintoDie1{
    background-color: rgb(249, 98, 43);
}
.QwintoDie2{
    background-color: gold;
}
.QwintoDie3{
    background-color: rgb(162, 0, 255);
}
.minus{
    background-color: brown;
    text-align: center;
}
.QwintoScore{
    background-color: rgb(116, 116, 116);
}
#equationSign{
    max-width: 20px;
}
.QwintoBoard input {
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    padding: 0;
    margin: 2px; /* Ensure no margin is applied */
    font-family: Arial, sans-serif;
    font-size: 40px;
    text-align: center;
}

.orangeRange, .yellowRange, .purpleRange {
    display: flex; /* Use flexbox to ensure even spacing */
    justify-content: flex-start; /* Align all elements to the start */
    text-align: center;
    margin: 0; /* Ensure no extra margin is applied to rows */
    padding: 0; /* Ensure no padding is applied to rows */
    align-items: center; /* Center inputs vertically */
}

.orangeRange{
    background-color: rgb(249, 98, 43);}
.yellowRange{
    background-color: gold;
}
.purpleRange{
    background-color: rgb(162, 0, 255);
}
#orangeRowScore{
    border-color:rgb(249, 98, 43);
}
#yellowRowScore{
    border-color:gold ;
}
#purpleRowScore{
    border-color:rgb(162, 0, 255);
}
#minusScore{
    background-color: brown;
}
.rollButton{
    box-sizing: border-box;
    width: 80px;
    height: 50px;
    padding: 0;
    margin: 8px;
    font-family: Arial, sans-serif;
    font-size: 20px;
    border: 3px solid black;
    text-align: center;
    border-radius: 13%;
    vertical-align: middle;
}

